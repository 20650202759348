import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { BasicDetailsLabel, Row } from "../styles";
import { StyledLabel } from "../../../internal/CreateParty/styles";
import FormField from "../../../common/FormField/FormField";
import { Formik } from "formik";
import { CustomFileUpload, CustomCheckbox } from "../../../common/FormInputs";
import { PrimaryCTAButton } from "../../../common/Buttons";
import AWSService from "../../../../services/AWSService";
import { AddAgentValidationSchema } from "../../../../helpers/validationSchema";
import PartyService from "../../../../services/PartyService";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../../helpers/functions";
import useLoader from "../../../../hooks/useLoader";
import { CheckBoxLabel, CheckboxContainer } from "../../CreateParty/styles";
import labels from "../../../../helpers/labels.json";
import useAlert from "../../../../hooks/useAlert";
import MultipleCustomSelect from "../../../common/CustomSelect/MultipleCustomSelect";
import MultipleCustomSelectModal from "./AssignCaseModal";

const validFormat = /(?:image|pdf)/;

const inputs = [
  {
    type: "email",
    name: "email",
    label: labels["agent.email"],
    required: true,
  },
  {
    type: "text",
    name: "pan",
    label: labels["agent.pan"],
  },
];

export default function Index({ state, setState, party = {}, setParty }) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const { showAlert, hideAlert } = useAlert();
  const [caseIds, setCaseIds] = useState([]);
  const [modal, setModal] = useState({ state: false });

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        const errorMessage = "Invalid File Type";
        if (!validFormat.test(file?.type)) throw errorMessage;
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
          enqueueSnackbar("Upload was successful", { variant: "success" });
        }
      } catch (error) {
        const message = getErrorMessage(error);
        setFieldError(field, "Could not upload this file");
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  async function onFormSubmit(values, { resetForm }) {
    const { email, pan, uri } = values;
    try {
      setLoader({ state: true, message: "Creating Agent..." });
      const payload = {
        email,
        panNumber: pan,
        authorizationLetterUrl: uri,
        role: values?.role ? "primary" : "normal",
        caseIds: caseIds
          .map((item) => item.id)
          .filter((item) => item !== undefined),
      };
      if (!caseIds?.length) delete payload.caseIds;
      const response = await PartyService.agentCreate(party.id, payload);
      if (response) {
        let newParty = { ...party };
        newParty.agents = [...party.agents, response.agent];
        setParty(newParty);
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        resetForm();
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }
  async function handleDeleteAgent(id) {
    try {
      if (id) {
        setLoader({ state: true, message: "Deleting Agent..." });
        const response = await PartyService.agentDelete(id, party.id);
        if (response) {
          let newParty = { ...party };
          newParty.agents = party.agents.filter((agent) => agent.id !== id);
          setParty(newParty);
          enqueueSnackbar(response.message, {
            variant: "success",
          });
        }
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      hideAlert();
    }
  }

  return (
    <Drawer
      anchor={"right"}
      open={state}
      onClose={() => setState(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <StyledLabel tooltip="An agent is a user on the system who is a Party or acting on behalf of a Party. The agent can be a counsel, advocate, friend or family of the party and will have rights to act on behalf of the respective Party">
            <Heading>{labels["actions.add_agents"]}</Heading>
          </StyledLabel>
          <CloseModal
            onClick={() => setState(false)}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        {party?.agents?.length > 0 && (
          <AgentListContainer>
            <BasicDetailsLabel>
              {labels["agents.added_agents"]} {party?.name}
            </BasicDetailsLabel>
            {party?.agents?.map((agent, index) => (
              <JustifySpaceBetween key={index}>
                <Row>
                  <AgentImage
                    src={
                      agent?.user?.avatarUrl?.length
                        ? agent.user.avatarUrl
                        : require("../../../../assets/images/userPlaceholder.svg")
                    }
                  />
                  <AgentName
                    onClick={() => setModal({ state: true, data: agent })}
                  >
                    {agent.user?.name ? agent.user?.name : agent.email}
                  </AgentName>
                </Row>
                <DeleteIcon
                  onClick={() =>
                    showAlert({
                      heading: "Remove Agent?",
                      desc: `Are you sure you want to dis-associate this Agent from the Party? This operation cannot be undone.`,
                      // desc:
                      //   "Are you sure you want to remove this agent from the party? This operation cannot be undone.",
                      primaryBtnText: "Remove",
                      secondaryBtnText: "Cancel",
                      clickSecondarybtn: () => hideAlert(),
                      clickPrimaryBtn: () => handleDeleteAgent(agent.id),
                      isOpen: true,
                      onDialogClose: () => hideAlert(),
                    })
                  }
                  src={require("../../../../assets/images/removeIcon.svg")}
                />
              </JustifySpaceBetween>
            ))}
          </AgentListContainer>
        )}
        <AgentFormContainer>
          <BasicDetailsLabel>
            {labels["agents.new_agent"]} {party?.name}
          </BasicDetailsLabel>
          <Formik
            initialValues={{ email: "", pan: "", uri: "", role: false }}
            enableReinitialize
            validateOnBlur
            validationSchema={AddAgentValidationSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                {inputs.map((input, index) => (
                  <FormField
                    key={index}
                    {...{
                      input,
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      setFieldValue,
                      setFieldError,
                      handleBlur,
                    }}
                  />
                ))}
                {/* commented for this card (https://app.clickup.com/t/1z8ejx)
                <SampleText>
                  {labels["agents.sample"]}{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://justact-api-uploads.s3.ap-south-1.amazonaws.com/1/1592213359658-054283fa-cd07-4e37-973b-eb494e2d905c-1590046534251-e0653346-fb8b-4ae5-8914-bcd88b35144d-dummy%20%282%29.pdf"
                      )
                    }
                  >
                    here
                  </span>
                </SampleText> */}
                <BasicDetailsLabel style={{ padding: "5px" }}>
                  {labels.authorisation_letter}{" "}
                  {/* {<span className="required-star"> *</span>} */}
                </BasicDetailsLabel>
                <CustomFileUpload
                  key={values.uri}
                  error={touched.uri && errors.uri}
                  file={{ name: getFileName(values.uri) }}
                  setFile={(file) =>
                    s3Upload(file, "uri", setFieldValue, setFieldError)
                  }
                  fileUrl={values?.uri}
                  accept=".pdf, image/*"
                  style={{
                    backgroundColor: COLORS.INPUT_BACKGROUND,
                    marginTop: 6,
                    marginBottom: 13,
                  }}
                />
                <BasicDetailsLabel style={{ padding: "5px" }}>
                  {"Assign case"}{" "}
                </BasicDetailsLabel>
                <MultipleCustomSelect
                  id={party.id}
                  {...{ caseIds, setCaseIds }}
                />
                <CheckboxContainer style={{ marginBottom: 20, marginTop: 10 }}>
                  <CustomCheckbox
                    name="role"
                    checked={values.role}
                    onChange={handleChange}
                  />
                  <CheckBoxLabel
                    onClick={() => setFieldValue("role", !values.role)}
                  >
                    {labels["agent.primary_agent"]}
                  </CheckBoxLabel>
                </CheckboxContainer>
                <PrimaryCTAButton onClick={handleSubmit}>
                  {labels["buttons.add_agent"]}
                </PrimaryCTAButton>
              </form>
            )}
          </Formik>
          {modal?.state && (
            <MultipleCustomSelectModal id={party.id} {...{ modal, setModal }} />
          )}
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: 555px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const JustifySpaceBetween = styled(Row)`
  justify-content: space-between;
  margin-top: 24px;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentListContainer = styled.div`
  padding-left: 30px;
  padding-right: 54px;
  padding-top: 47px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
`;

const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
`;

const AgentImage = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 12px;
`;

const AgentName = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  cursor: pointer;
  text-decoration: underline;
`;

const AgentFormContainer = styled.div`
  padding-top: 26px;
  padding-left: 28px;
  padding-right: 94px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;
