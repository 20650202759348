import API from "../api/manager";
import config from "../api/config";

const setConfig = (data) => {
  return API({
    method: "POST",
    url: config.urls.systemConfig,
    data,
  });
};

const getConfig = () => {
  return API(
    {
      method: "GET",
      url: `${config.urls.systemConfig}?role=agent`,
    },
    false,
  );
};

const getProfile = (id) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/${id}`,
  });
};

const getConfigAuth = () => {
  return API({
    method: "GET",
    url: config.urls.systemConfig,
  });
};

export default {
  getConfig,
  setConfig,
  getProfile,
  getConfigAuth,
};
