import React, { useEffect } from "react";
import { Content, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function CommercialDisputes(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Mediation in Commercial Disputes</HeadWrap>
          <br />
          <br />
          <Content>
            <b
              style={{
                textAlign: "left",
                fontSize: "16px",
                fontStyle: "italic",
              }}
            >
              {" "}
              Authored by : Aprameya Manthena{" "}
            </b>
            <p style={{ marginBottom: "10px", marginTop: "10px" }}>
              The Commercial Courts Act, 2015 was enacted by the Parliament in
              order to facilitate ease of doing business and encourage foreign
              capital and investment in the country. Commercial disputes have
              been defined under the Act to include agreements relating to
              franchising, distribution and licensing, management and
              consultancy, joint ventures, shareholders’, export or import of
              merchandise or services, construction and infrastructure
              contracts, including tenders among other categories.
            </p>
            <p>
              The mandatory or directory nature of the stipulation under Section
              12A<sup>1</sup> of the Commercial Courts Act came up for
              discussion in 
              <span style={{ fontStyle: "italic" }}>
                Patil Automation Private Limited v. Raheja Engineers Private
                Limited <sup>2</sup>
              </span>{" "}
              and the Supreme Court held that it was a compulsory requirement
              for parties to access the option of mediation before filing a suit
              in a commercial dispute.
            </p>
            <br />
            <p>
              The debate around mandatory pre-litigation mediation in commercial
              disputes is twofold- 1) whether the compulsory referring of the
              dispute to mediation could impede the speedy resolution of
              disputes and 2) whether the option turns futile because the other
              party refuses to accept or continue with the invitation to
              mediate. As an aside, it is ironic that a measure that is meant to
              decongest courts of pending cases could become an impediment to
              quick and effective dispute resolution in and of itself.
            </p>
            <br />
            <p>
              The Mediation Act, 2021 also mandates pre-litigation mediation for
              civil or commercial disputes of particular value<sup>3</sup> . It
              also provides for online mediation as a mode of resolving disputes
              thus bringing convenience, cost effectiveness and technological
              ease into the dispute resolution ecosystem.<sup>4</sup> Mediators
              may be appointed by parties or mediation service providers which
              empowers the parties to approach those professionals whose
              credentials they trust.
            </p>
            <br />
            <p>
              The government is also a party in numerous commercial suits and
              contracts-- mediation being mandatory under the various statutes
              could thus cast a shadow over the autonomy of the parties and
              efficacy of the proceedings when the Mediation Council’s remit is
              subject to the Central government’s approval as provided for by
              the Act <sup>5</sup> . It could also signal trouble when
              responsibility has to be apportioned--- due to the bureaucratic
              long arm, officials representing the government might refuse to
              strike compromises due to the fear of harsh consequences.
            </p>
            <br />
            <p>
              Also, mediations are inevitably delayed when caught in the complex
              web of fraught negotiations and uneasy standoffs. In company
              matters especially, authorised representatives (or authorized
              signatories) relaying information regarding the proceedings to the
              higher-ups, possess the authority to discuss, make decisions and
              sign on behalf of the company. However, it has been noticed that
              mediation sessions get prolonged when such representatives seek
              time to acquire the requisite instructions from the company
              management. There is perhaps the fear of being forced to bear
              higher degrees of risk which could lead to
            </p>
            <br />
            <p>
              untoward professional ramifications. The Mediation Act however
              mandates 180 days as the time limit within which mediations are
              expected to be concluded with a permissible extension of a further
              period of up to 180 days.<sup>6</sup>
            </p>
            <br />
            <p>
              Section 442 of the Companies Act, 2013 read with Rule 3 of the
              Companies (Mediation and Conciliation Rules), 2016 provides for
              the setting up of a Mediation and Conciliation Panel by the
              Central Government to which matters could be referred at any stage
              of the proceedings. The Central Government, Tribunal or Appellate
              Tribunal also have the powers to refer the matter to the Panel.
              The provisions of the Arbitration and Conciliation Act, 1996 apply
              in the same manner.
            </p>
            <br />
            <p>
              In conclusion, considering that commercial disputes move at
              snail’s pace in the court system, mediation is an option that
              parties could definitely explore especially when they encounter
              the inevitable relational friction, regardless of the existence of
              a mediation agreement.
            </p>
            <Block />
            <p>
              <sup>1</sup> The Commercial Courts, Commercial Division and
              Commercial Appellate Division of High Courts (Amendment) Act, 2018
            </p>
            <p>
              <sup>2</sup> This judgment considered Dhanbad Fuels Ltd. v. Union
              of India, 2021 SCC OnLine Cal 429
            </p>
            <p>
              <sup>3</sup> Section 6
            </p>
            <p>
              <sup>4</sup> Section 32
            </p>
            <p>
              <sup>5</sup> Section 53
            </p>
            <p>
              <sup>6</sup> Section 21
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default CommercialDisputes;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
