import React, { useState, useRef, useEffect } from "react";
import { OTPContainer } from "./styles";
import { Center } from "../../../styles/component/style";
import { Otp } from "../../common/Otp/Otp";
import { Header } from "../../internal/Header/Header";
import { navigate } from "@reach/router";
import AuthService from "../../../services/AuthService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import _ from "lodash";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";

const Index = ({ location }) => {
  const [value, setValue] = useState("");
  const { state } = location;
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useUser();
  const { setLoader } = useLoader();
  const codeRef = useRef(undefined);
  const [sending, setSending] = useState(false);

  const resendOtp = async () => {
    try {
      setLoader({ state: true });
      const response = await AuthService.login(state.payload);
      if (response) {
        enqueueSnackbar("OTP Sent Successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoader({ state: true });
      setSending(true);
      let payload = _.pick(state.payload, ["mobile"]);
      payload.otp = value;
      const response = await AuthService.otpLogin(payload);
      if (response && response.token) {
        setToken(response.token);
        navigate("/dashboard");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
      clearInputs();
    } finally {
      setValue("");
      setLoader({ state: false });
      setSending(false);
    }
  };

  const clearInputs = () => {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input[0] = "";
    codeRef.current.state.input[1] = "";
    codeRef.current.state.input[2] = "";
    codeRef.current.state.input[3] = "";
    codeRef.current.state.input[4] = "";
    codeRef.current.state.input[5] = "";
  };

  const enterHandler = (event) => {
    if (codeRef.current) {
      if (codeRef.current.state.value.length === 6) {
        if (event.key === "Enter") {
          handleSubmit();
        }
      }
    }
  };
  useEffect(() => {
    const enterHandler = (event) => {
      if (!sending) {
        if (codeRef.current) {
          if (codeRef.current.state.value.length === 6) {
            if (event.key === "Enter") {
              handleSubmit();
            }
          }
        }
      }
    };
    window.addEventListener("keyup", enterHandler);
    return () => {
      window.removeEventListener("keyup", enterHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, codeRef]);

  const editTriggered = () => {
    navigate("/login", {
      state: {
        payload: state.values,
      },
    });
  };
  return (
    <Header>
      <Center>
        <OTPContainer>
          <Otp
            heading="LOGIN"
            disableSubmit={value.length < 6}
            submitLabel="Proceed"
            phoneNumber={`${state?.payload.mobile}`}
            timer={60}
            isTimmerEnabel={true}
            triggerResent={resendOtp}
            triggerEdit={editTriggered}
            numberOfInput={6}
            inputName="otp"
            value={value}
            setValue={setValue}
            handleSubmit={handleSubmit}
            showArrow
            codeRef={codeRef}
            enterHandler={enterHandler}
          />
        </OTPContainer>
      </Center>
    </Header>
  );
};

export default Index;
