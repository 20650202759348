import React, { useEffect, useState } from "react";
import AuthService from "../services/AuthService";
import { navigate } from "@reach/router";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../helpers/functions";
import SystemService from "../services/SystemService";

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [token, setToken] = useState(null);
  const [userObject, setUserObject] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState(null);
  const [authSetting, setAuthSetting] = useState({});

  useEffect(() => {
    try {
      function getToken() {
        try {
          const local_token = localStorage.getItem("auth_token");
          const local_token_password = localStorage.getItem("change-password");
          if (local_token) setToken(local_token);
          if (local_token_password) setPassword(local_token_password);
        } catch (error) {
          console.log(error);
        }
      }
      getToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sortNeutralData = (data) => {
    data = data.sort((a, b) => {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return data;
  };

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        const user = await AuthService.profile();
        const response = await SystemService.getConfigAuth();
        if (response.systemConfig) {
          let data = response.systemConfig.arbitratorsInfo || [];
          response.systemConfig.arbitratorsInfo = await sortNeutralData(data);
          data = response.systemConfig.mediatorsInfo || [];
          response.systemConfig.mediatorsInfo = await sortNeutralData(data);
          setAuthSetting(response.systemConfig);
        }
        if (user?.me) {
          setUserObject(user.me);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    try {
      if (token) {
        if (token === "logged_out") {
          localStorage.setItem("auth_token", "");
          navigate("/");
          setUserObject({});
          setToken(null);
        } else {
          localStorage.setItem("auth_token", token);
          localStorage.setItem("change-password", password);
          fetchUserProfile();
        }
      }
    } catch (error) {
      console.log(error);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const value = React.useMemo(() => {
    return {
      token,
      setToken,
      userObject,
      setUserObject,
      password,
      setPassword,
      authSetting,
      setAuthSetting,
    };
  }, [
    token,
    setToken,
    userObject,
    setUserObject,
    password,
    setPassword,
    authSetting,
    setAuthSetting,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
