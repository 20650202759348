import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { getErrorMessage } from "../helpers/functions";
import SystemService from "../services/SystemService";
import _ from "lodash";

export const SettingsContext = React.createContext();

function SettingsProvider({ children }) {
  const [setting, setSetting] = useState({});
  const [settingsLoading, setSettingsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  function getFaviconEl(id) {
    return document.getElementById(id);
  }

  useEffect(() => {
    if (!_.isEmpty(setting)) {
      const favicon_32 = getFaviconEl("favicon_32");
      const favicon_16 = getFaviconEl("favicon_16");
      favicon_32.href = setting?.faviconUrl;
      favicon_16.href = setting?.faviconUrl;
    }
  }, [setting]);

  useEffect(() => {
    async function getSystemConfig() {
      setSettingsLoading(true);
      try {
        const authToken = localStorage.getItem("auth_token");
        if (!authToken) {
          const response = await SystemService.getConfig();
          if (response.systemConfig) {
            let data = response.systemConfig.arbitratorsInfo || [];
            response.systemConfig.arbitratorsInfo = await sortNeutralData(data);
            data = response.systemConfig.mediatorsInfo || [];
            response.systemConfig.mediatorsInfo = await sortNeutralData(data);
            setSetting(response.systemConfig);
          }
        }
        setSettingsLoading(false);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        setSettingsLoading(false);
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortNeutralData = (data) => {
    data = data.sort((a, b) => {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    return data;
  };

  const value = React.useMemo(() => {
    return {
      setting,
      setSetting,
      settingsLoading,
    };
  }, [setting, setSetting, settingsLoading]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;
