import React from "react";
import {
  FooterWrapper,
  LogoWrapper,
  NavLinks,
  NavLink,
  Copyrights,
  CompanyLogoWhite
} from "./Styles";
import { Images } from "../../../assets/images";
import { Link } from "react-scroll";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import { navigate } from "@reach/router";
import styled from "styled-components";

const Footer = () => {

  // const Links = [
  //   {
  //     label: "About Us",
  //     url: "aboutus",
  //     isWithinLanding: true
  //   },
  //   {
  //     label: "Legal",
  //     url: "/",
  //     isWithinLanding: false
  //   },
  //   {
  //     label: "Terms & Conditions",
  //     url: "T&C",
  //     isWithinLanding: false
  //   },
  //   {
  //     label: "Privacy Policy",
  //     url: "/",
  //     isWithinLanding: false
  //   },
  //   {
  //     label: "Partnerships",
  //     url: "/",
  //     isWithinLanding: false
  //   },
  //   {
  //     label: "Contact Us",
  //     url: "contact_us",
  //     isWithinLanding: true
  //   }
  // ];

  return (
    <FooterWrapper>
      <Link to="hero" spy={true} smooth={true} offset={-70} duration={500}>
        <LogoWrapper>
          <CompanyLogoWhite src={Images.companyLogoWhite} />
        </LogoWrapper>
      </Link>
      {/* <NavLinks>
        {Links.map((link, index) => {
          return (
            <NavLink key={index} onClick={() => navigateToLink(link)}>
              {link.label}
            </NavLink>
          );
        })}
      </NavLinks> */}
      <NavLinks>
        <NavLink
          onClick={() => navigate("https://www.linkedin.com/company/justact/")}
        >
          <LinkedInIcon />
        </NavLink>
        <NavLink onClick={() => navigate("")}>
          <TwitterIcon />
        </NavLink>
        <NavLink onClick={() => navigate("")}>
          <FacebookIcon />
        </NavLink>
        <NavLink
          onClick={() =>
            navigate("https://www.youtube.com/channel/UCWEn7whMx2ZX10OUzTeYvhA")
          }
        >
          <YouTubeIcon />
        </NavLink>
        <NavLink
          onClick={() => navigate("https://www.instagram.com/_just_act_/")}
        >
          <InstagramIcon />
        </NavLink>
        <NavLink onClick={() => navigate("https://wa.me/+919500909888")}>
          <WhatsAppIcon />
        </NavLink>
        <NavLink onClick={() => navigate("mailto:support@justact.co.in")}>
          <EmailIcon />
        </NavLink>
        <NavLink
          onClick={() =>
            navigate(
              "https://play.google.com/store/apps/details?id=stouch.os.justact&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            )
          }
        >
          <ProfileImage
            style={{ width: 85 }}
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </NavLink>
      </NavLinks>
      <Copyrights>
        Copyright © {new Date().getFullYear()} Lex Carta Private Limited <br />
        T4, 7th street, VSI Estate Phase 2,
        <br /> Thiruvanmiyur, Chennai, Tamil Nadu 600041, India
        <br />
        All Rights Reserved.
        <br />
        <a
          style={{ color: "white" }}
          href="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        <br />
        <a
          style={{ color: "white" }}
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </Copyrights>
    </FooterWrapper>
  );
};

export default Footer;

export const ProfileImage = styled.img``;